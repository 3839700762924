.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(30px);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999;
  overflow: hidden;
}

.modal-container {
  width: calc(100% - 72px);
  max-width: 600px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 32px 36px;
  border-radius: 32px;

  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  z-index: 1001;

  @media screen and (max-width: 480px) {
    width: calc(100% - 32px);

    padding: 24px;
    border-radius: 24px;
  }

  .modal-title {
    color: #2b2e3f;
    font-family: Figtree;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.22px;

    @media screen and (max-width: 480px) {
      font-size: 22px;
      line-height: 22px;
    }
  }

  .modal-desc {
    color: #2b2e3f;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 137.5% */
    letter-spacing: 0.16px;

    @media screen and (max-width: 480px) {
      font-size: 15px;
    }
  }

  .bold {
    font-weight: 600;
  }

  .modal-button-wrapper {
    width: 100%;

    display: flex;
    justify-content: flex-end;
    gap: 12px;

    margin-top: 16px;

    .modal-button {
      padding: 16px 24px;

      border-radius: 16px;

      font-family: Figtree;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.16px;

      text-align: center;
    }

    .agree {
      color: #ffffff;
      background-color: #563ddb;

      &:hover {
        background-color: #4938a8;
      }

      @media screen and (max-width: 480px) {
        width: 100%;

        line-height: 20px;
      }
    }

    .disagree {
      color: #2b2e3f;
      background-color: #e0e0e0;

      &:hover {
        background-color: #c5c5c5;
      }

      @media screen and (max-width: 480px) {
        width: 100%;

        line-height: 20px;
      }
    }
  }
}

.modal-container button {
  margin: 10px;
  padding: 10px 20px;
}
