.tp-main-getstarted {
  width: 100%;
  height: auto;

  overflow: hidden;

  .tp-main-getstarted-wrapper {
    width: calc(100% - 72px);
    max-width: 1440px;
    height: auto;

    margin: auto;
    padding: 120px 0;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1600px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 992px) {
      height: 1100px;
      padding: 0;
    }

    @media screen and (max-width: 480px) {
      width: calc(100% - 32px);
      padding: 80px 0;

      height: 900px;
    }

    .getstarted-left-content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      width: 100%;

      @media screen and (max-width: 992px) {
        align-items: center;
      }

      @media screen and (max-width: 480px) {
        align-items: center;
      }

      .getstarted-left-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        width: 100%;

        @media screen and (max-width: 992px) {
          align-items: center;
        }
      }

      .getstarted-top-title {
        color: #563ddb;

        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 156.25% */
        letter-spacing: 0.16px;
        text-transform: uppercase;

        @media screen and (max-width: 992px) {
          text-align: center;
        }

        @media screen and (max-width: 480px) {
          text-align: center;
        }
      }

      .getstarted-title {
        width: 100%;

        color: #2b2e3f;

        font-feature-settings: "salt" on;
        font-family: "Futura BdCn BT";
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px; /* 107.143% */
        letter-spacing: -1.12px;
        text-transform: uppercase;

        @media screen and (max-width: 992px) {
          text-align: center;
        }

        @media screen and (max-width: 480px) {
          height: 142px;
          line-height: 56px;

          display: flex;

          text-align: center;
        }
      }

      .getstarted-desc {
        max-width: 600px;
        color: #2b2e3f;

        font-family: Figtree;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 125% */
        letter-spacing: 0.2px;

        @media screen and (max-width: 992px) {
          text-align: center;
        }

        @media screen and (max-width: 480px) {
          text-align: center;
          font-size: 16px;
          line-height: 21px;
          width: 95%;
        }
      }
    }

    .getstarted-right-content {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 36px;
    }
  }
}

.payment-img {
  width: 100%;
  height: auto;

  @media screen and (max-width: 992px) {
    max-width: 500px;
  }
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media screen and (max-width: 480px) {
    gap: 8px;
    flex-wrap: wrap;
  }

  .fixed-width {
    width: 100px;
  }
}

.get-started-bg {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(0%, -50%);

  z-index: -1;

  @media screen and (max-width: 992px) {
    transform: translate(-50%, -50%);

    width: 100%;
  }
}

.break-first-title {
  display: block;

  margin: auto;
  word-break: normal;

  @media screen and (max-width: 992px) {
    text-align: center;
  }
}

.mobile {
  min-height: 240px;
}

.mobile-height {
  max-height: 1000px;
}
