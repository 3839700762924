.max-width {
  width: calc(100% - 72px);
  max-width: 1440px;
  height: auto;

  margin: auto;

  @media screen and (max-width: 768px) {
    width: calc(100% - 32px);
  }
}
