.tp-cardinfo-main-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;

  padding: 120px 0;

  background-color: #000000;

  @media screen and (max-width: 768px) {
    gap: 40px;
  }

  .tp-cardinfo-top {
    width: calc(100% - 72px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    color: #ffffff;

    text-align: center;

    @media screen and (max-width: 768px) {
      width: calc(100% - 32px);
      gap: 16px;
    }

    .section-title {
      font-family: Futura BdCn BT;
      font-weight: 400;
      font-size: 90px;
      line-height: 1;
      letter-spacing: -2%;
      text-align: center;
      text-transform: uppercase;

      @media screen and (max-width: 1024px) {
        font-size: 76px;
      }

      @media screen and (max-width: 460px) {
        font-size: 46px;
      }
    }

    .desc-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .section-first-desc {
        font-family: Figtree;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 1%;
        text-align: center;

        @media screen and (max-width: 460px) {
          font-size: 20px;
        }
      }

      .section-second-desc {
        font-family: Figtree;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 1%;
        text-align: center;

        @media screen and (max-width: 460px) {
          font-size: 16px;
        }
      }
    }
  }

  video {
    width: 100%;
    max-width: 744px;
  }
}
