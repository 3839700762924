@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.tpcard-cansave-container {
  width: 100%;
  height: auto;
  background-color: #f4f4f4;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 120px 0;

  .section-title {
    color: #2b2e3f;
    text-align: center;

    margin: 0 72px;

    @media screen and (max-width: 480px) {
      margin: 0 32px;
    }
  }

  .section-desc {
    max-width: 780px;

    margin-top: 12px;

    color: #2b2e3f;
    text-align: center;

    margin: 12px 72px;

    @media screen and (max-width: 480px) {
      margin: 12px 32px;
    }
  }

  .tpcard-cansave-wrapper {
    max-width: 1250px;

    background-color: #ffffff;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 32px;
    margin: 60px auto 30px;

    @media screen and (max-width: 992px) {
      margin: 40px auto 20px;
    }

    .tpcard-cansave-calculator {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;

      padding: 40px 32px;

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }

      @media screen and (max-width: 768px) {
        padding: 30px 24px;
      }

      .slider-container {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 20px;
        }

        .slider {
          -webkit-appearance: none; // Remove default styles
          appearance: none;
          height: 10px;
          width: 200px;

          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 500px;

          background: #563ddb; // Change to your desired color

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 26px;
            height: 26px;
            background: #ffffff; // Change to your desired color
            border: 2px solid #563ddb; // Match with the bar color
            border-radius: 50%;
            cursor: pointer;
          }
        }

        .slider-value {
          min-width: 136px;
          color: #563ddb; // Match with slider color
        }
      }
    }

    .tpcard-cansave-chart-header {
      width: 100%;
      max-width: 1250px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      border-top-right-radius: 32px;
      border-top-left-radius: 32px;

      text-align: center;

      color: #ffffff;
      background-color: #563ddb;

      padding: 24px 32px;

      @media screen and (max-width: 992px) {
        gap: 8px;
      }
    }

    .tpcard-cansave-chart-container {
      width: 100%;
      max-width: 1250px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      color: #2b2e3f;

      padding: 32px;

      @media screen and (max-width: 992px) {
        padding: 32px 24px;
      }

      @media screen and (max-width: 768px) {
        padding: 24px 16px;
      }

      .tpcard-cansave-chart-panel {
        width: 100%;

        padding: 0 16px;

        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-of-type(1) {
          max-width: 300px;
          .slot {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            text-align: left;
          }
        }

        &:nth-of-type(2) {
          background-color: #e6dfff;
          border-radius: 26px;
        }

        .header-row {
          display: flex;
          flex-direction: column;
          gap: 16px !important;

          min-height: 160px !important;
        }

        .underlined {
          display: flex;
          flex-direction: column;
          gap: 12px !important;

          border-bottom: #838383 solid 0.5px;
        }

        .slot-row {
          min-height: 126px !important;
          padding: 16px 0;

          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px !important;
        }

        .first-column {
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;
          justify-content: center !important;
          text-align: left !important;
        }

        .slot {
          width: 100%;
          max-width: 220px;
          height: auto;
          min-height: 90px;
          max-height: 126px;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          text-align: center;
        }
      }
    }

    .tpcard-cansave-chart-footer {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      color: #2b2e3f;
      background-color: #e6dfff;

      padding: 32px;

      text-align: center;

      @media screen and (max-width: 480px) {
        padding: 24px;
      }

      .purple {
        color: #563ddb;
      }

      p {
        line-height: 30px;
        @media screen and (max-width: 480px) {
          line-height: 26px;
        }

        span {
          white-space: normal;
        }
      }
    }

    .tpcard-cansive-mobile-container {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      padding: 32px;

      @media screen and (max-width: 480px) {
        padding: 0;
        gap: 0;
      }

      .tpcard-cansive-mobile-wrapper {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border-radius: 26px;
        padding: 16px;

        background-color: #f4f4f4;

        cursor: pointer;

        @media screen and (max-width: 480px) {
          background-color: #ffffff;
          padding: 0;
        }

        &:nth-of-type(1) {
          background-color: #e6dfff;

          @media screen and (max-width: 480px) {
            background-color: #ffffff;
          }

          .detail-panel-ts {
            color: #ffffff;
            background-color: #563ddb;
          }
        }

        .tpcard-cansive-mobile-header {
          width: 100%;

          display: flex;
          justify-content: space-between;
          align-items: center;

          padding: 8px;

          @media screen and (max-width: 480px) {
            padding: 20px 16px;
          }

          .header-left {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }

        .detail-panel-container {
          width: 100%;
          overflow: hidden;

          .detail-panel {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 12px;

            @media screen and (max-width: 480px) {
              margin: 0;
              padding: 16px;
            }

            &:nth-of-type(1) {
              margin-top: 12px;
              padding-top: 24px;
              border-top: #838383 solid 1px;

              @media screen and (max-width: 480px) {
                margin: 0;
              }
            }

            &:nth-of-type(4) {
              padding-bottom: 24px !important;
            }

            &:nth-of-type(5) {
              background-color: #c6c6c6;
            }

            .label {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 6px;
            }
          }
        }

        .detail-panel-ts {
          width: 100%;

          display: flex;
          justify-content: space-between;

          padding: 16px;
          margin-top: 16px;

          border-radius: 16px;

          background-color: #c6c6c6;

          @media screen and (max-width: 480px) {
            border-radius: 0;
            margin-top: 0;
          }

          .panel {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .bottom-text {
    color: #838383;
    text-align: center;

    margin: 0 16px;
  }
}
