.tp-main-app-footer {
  width: 100%;
  height: auto;

  overflow: hidden;

  border-top: 1px solid #dbdbdb;

  .tp-main-app-footer-wrapper {
    width: calc(100% - 72px);
    max-width: 1440px;
    height: max-content;

    margin: auto;

    position: relative;

    padding: 60px 0;
    gap: 60px;

    display: flex;
    flex-direction: column;

    @media (max-width: 1600px) {
      max-width: 1200px;
    }

    .app-footer-top {
      display: flex;
      justify-content: space-between;
      gap: 24px;

      @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 60px;
      }

      input {
        border: none;
        outline: none;
        background: none;
        padding: 0;
        margin: 0;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        /* Add any custom styles here */
        max-width: 300px;

        color: #545454;
        text-align: left;
        leading-trim: both;

        text-edge: cap;
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 100% */
        letter-spacing: 0.18px;

        padding: 12px 16px;

        border-radius: 16px;

        background: #f4f4f4;
      }
    }

    .torum-pay-logo {
      width: auto;
      max-height: 42px;
    }

    .app-footer-bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 36px;

      // padding: 16px 0 32px;

      // border-top: 1px solid #dbdbdb;

      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
    }

    .app-footer-disclaimer {
      color: #838383;
      font-family: Figtree;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: -0.13px;
    }
  }
}

.website-copyright {
  color: #838383;
  text-align: right;
  font-family: Figtree;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 166.667% */
  letter-spacing: -0.13px;

  text-align: left;
}

.app-footer-sub-links-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 60px;

  @media screen and (max-width: 480px) {
    justify-content: space-between;
  }

  .app-footer-sub-links {
    min-width: 220px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 1200px) {
      min-width: 170px;
    }

    @media screen and (max-width: 480px) {
      min-width: 162px;
      gap: 16px;
    }

    .sub-links {
      a {
        font-size: 15px;
        text-decoration: none;
        color: #838383;
      }
    }
  }
}
