@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.gray {
  color: #838383;
}

.tpreferral-main-container {
  .tpreferral-hero-container {
    width: 100%;
    height: auto;

    padding: 120px 0 500px;

    position: relative;

    color: #ffffff;
    background: radial-gradient(circle at 30% 40%, #c1308d 0%, transparent 50%),
      radial-gradient(circle at 70% 60%, #152fd9 0%, transparent 50%),
      radial-gradient(circle at 50% 50%, #7856ff 0%, transparent 50%), #000000; /* Base background */

    @media screen and (max-width: 992px) {
      padding: 120px 0 420px;
    }

    @media screen and (max-width: 768px) {
      padding: 120px 0 320px;
    }

    @media screen and (max-width: 600px) {
      padding: 100px 0 520px;
    }

    @media screen and (max-width: 480px) {
      padding: 80px 0 340px;
    }

    @media screen and (max-width: 425px) {
      padding: 80px 0 320px;
    }

    @media screen and (max-width: 375px) {
      padding: 80px 0 280px;
    }

    @media screen and (max-width: 320px) {
      padding: 60px 0 220px;
    }

    // Hero Section - START ////////////////////////////////////////////////////////////////////////////
    .tpreferral-hero-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .hero-title {
        text-transform: uppercase;
        text-align: center;
      }

      .hero-desc {
        width: 100%;
        max-width: 540px;

        text-align: center;

        &:nth-of-type(2) {
          margin-top: 24px;

          @media screen and (max-width: 520px) {
            margin-top: 16px;
          }
        }
      }

      .hero-button {
        width: max-content;

        margin-top: 36px;
        padding: 16px 32px;
        border-radius: 16px;

        font-family: Figtree;
        font-size: 18px;
        font-weight: 600;

        color: #000000;
        background-color: #ffffff;
      }

      .hero-img {
        width: calc(100% - 72px);
        max-width: 880px;

        position: absolute;

        bottom: -200px;

        @media screen and (max-width: 768px) {
          width: calc(100% - 32px);
        }

        @media screen and (max-width: 425px) {
          bottom: -160px;
        }
      }
    }
  }
  // HERO Section - END ////////////////////////////////////////////////////////////////////////////

  // Participate Section - START ////////////////////////////////////////////////////////////////////////////
  .tpreferral-participate-container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 300px 0 120px;

    color: #2b2e3f;

    @media screen and (max-width: 768px) {
      padding: 300px 0 80px;
    }

    @media screen and (max-width: 480px) {
      padding: 240px 0 80px;
    }

    .title {
      text-align: center;
    }

    .tpreferral-participate-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;

      margin-top: 56px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
      }

      .tpreferral-participate-panel {
        width: 100%;
        max-width: 420px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        padding: 48px 16px;

        @media screen and (max-width: 768px) {
          padding: 24px 16px;
          gap: 12px;
        }

        img {
          width: 100%;
          max-width: 266px;
          height: auto;
        }

        .title {
          text-align: center;
        }

        .desc {
          text-align: center;
        }
      }
    }
  }
  // Participate Section - END ////////////////////////////////////////////////////////////////////////////

  // Journey Section -START ////////////////////////////////////////////////////////////////////////////
  .tpreferral-journey-container {
    width: 100%;
    height: auto;

    background-color: #f4f4f4;

    padding: 120px 0;

    color: #2b2e3f;

    @media screen and (max-width: 768px) {
      padding: 80px 0;
    }

    .tpreferral-journey-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 220px;

      @media screen and (max-width: 1440px) {
        gap: 24px;
      }

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }

      .left-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        width: 100%;
        max-width: 530px;

        @media screen and (max-width: 992px) {
          text-align: center;
        }
      }

      img {
        width: 100%;
        max-width: 500px;
      }
    }
  }
  // Journey Section - END ////////////////////////////////////////////////////////////////////////////

  // Participate Section - START ////////////////////////////////////////////////////////////////////////////
  .tpreferral-rewards-container {
    width: 100%;

    padding: 120px 0;

    @media screen and (max-width: 768px) {
      padding: 80px 0;
    }

    .tpreferral-rewards-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 48px;

      .header-section {
        display: flex;
        flex-direction: column;
        gap: 24px;

        text-align: center;

        color: #2b2e3f;
      }

      .chart-color-wrapper {
        width: 100%;
        max-width: 1200px;

        background: linear-gradient(
          90deg,
          #00003b 0%,
          #1d43f9 50.1%,
          #ca2f8b 100%
        );
        -webkit-box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.26);
        -moz-box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.26);
        box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.26);

        padding-bottom: 24px;
        border-radius: 32px;

        overflow: hidden;

        @media screen and (max-width: 1024px) {
          max-width: 660px;
        }

        @media screen and (max-width: 520px) {
          padding: 0;
          background: none;
          border-radius: 24px;
        }

        .tpreferral-rewards-chart-wrapper {
          display: flex;
          flex-direction: column;

          padding: 16px 32px 32px;

          border-bottom-right-radius: 32px;
          border-bottom-left-radius: 32px;
          background-color: #ffffff;

          @media screen and (max-width: 1024px) {
            flex-direction: row;
            width: 100%;
            padding: 32px;
          }

          @media screen and (max-width: 520px) {
            padding: 0;
          }

          .tpreferral-rewards-panel {
            width: 100%;
            max-width: 1200px;
            display: grid;
            grid-template-columns: 1fr repeat(5, minmax(0, 186px));
            grid-template-rows: 90px;

            @media screen and (max-width: 1024px) {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              width: 100%;
              height: auto;

              .label {
                border-bottom: 1px #838383 solid;
              }

              &:nth-of-type(2) {
                border-radius: 24px;

                color: #ffffff;
                background: linear-gradient(
                  #00003b 0%,
                  #1d43f9 50.1%,
                  #ca2f8b 100%
                );

                .label {
                  border-bottom: 1px #ffffff solid;
                }

                @media screen and (max-width: 520px) {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                }
              }
            }

            &:nth-of-type(4) {
              border-radius: 24px;

              color: #ffffff;
              background: linear-gradient(
                90deg,
                #00003b 0%,
                #1d43f9 50.1%,
                #ca2f8b 100%
              );
            }

            .label {
              width: 100%;
              min-width: 260px;
              height: 100%;

              display: flex;
              flex-direction: column;
              align-items: flex-start !important;
              justify-content: center;
              gap: 6px;

              padding: 0 24px;

              @media screen and (max-width: 1024px) {
                align-items: center !important;
                min-height: 100px;
                min-width: 100%;
                text-align: center;
                gap: 10px;
              }

              @media screen and (max-width: 520px) {
                height: auto;
                min-height: 120px;
                padding: 12px 12px;
              }
            }

            .data {
              display: flex;
              align-items: center;
              justify-content: center;

              padding: 30px 10px;

              text-wrap: nowrap;

              @media screen and (max-width: 1024px) {
                align-items: center !important;
                min-height: 90px;
                width: 100%;

                @media screen and (max-width: 520px) {
                  text-wrap: wrap;
                }
              }
            }
          }
        }
      }
    }
  }
  // Participate Section - END ////////////////////////////////////////////////////////////////////////////
}
