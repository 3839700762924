.tp-main-spotlight {
  width: 100%;

  .tp-main-spotlight-wrapper {
    width: calc(100% - 72px);
    max-width: 1440px;
    margin: auto;
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    gap: 56px;

    @media screen and (max-width: 1600px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 32px);
      padding: 80px 0;
      gap: 36px;
    }

    /* ===== Spotlight Header ===== */
    .spotlight-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .spotlight-header-title {
        color: #2b2e3f;
        text-transform: uppercase;
      }

      .spotlight-header-desc {
        color: #2b2e3f;
        text-align: center;

        @media screen and (max-width: 480px) {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    /* ===== Spotlight Grid Layout ===== */
    .tp-spotlight-grid {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(2, 1fr);
      transition: all 0.4s ease-in-out;

      /* ===== Individual Spotlight Items ===== */
      .spotlight-item {
        height: 460px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        text-align: left;
        position: relative;
        color: white;
        overflow: hidden;
        transition: all 0.4s ease-in-out;

        &:hover {
          img {
            max-width: 500px !important;
            transition: all 0.4s ease-in-out;

            @media (max-width: 1200px) {
              max-width: 480px;
            }
          }
        }

        /* 🌟 Centered Layout for Middle Items (Index 1-4) */
        &:nth-child(n + 2):nth-child(-n + 5) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          .spotlight-text-wrapper {
            padding: 48px 48px 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 16px;

            @media screen and (max-width: 768px) {
              padding: 36px 36px 0;
            }
          }

          .img-wrapper {
            display: flex;
            overflow: visible;
            justify-content: center;
            position: absolute;
            bottom: -100px;

            @media (max-width: 1200px) {
              bottom: -100px;
            }

            @media (max-width: 768px) {
              bottom: -80px;
            }

            @media (max-width: 480px) {
              bottom: -20px;
            }

            img {
              width: 100%;
              max-width: 480px;
              height: auto;
              object-fit: contain;
              object-position: bottom;
              transition: all 0.4s ease-in-out;
            }
          }
        }
      }

      /* 🌟 First and Last Items (Wide Layout) */
      .wide {
        grid-column: span 2;
        justify-content: space-between;
        max-height: 350px;

        @media (max-width: 1200px) {
          max-height: inherit;
          align-items: flex-start;
        }

        &:hover {
          img {
            max-width: 600px !important;
            transition: all 0.4s ease-in-out;

            @media (max-width: 1200px) {
              max-width: 550px;
            }
          }
        }

        .spotlight-text-wrapper {
          max-width: 600px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 48px;
          z-index: 1;

          @media (max-width: 1200px) {
            max-width: 100%;
          }

          @media screen and (max-width: 768px) {
            padding: 36px 36px 0;
          }
        }

        &:last-child {
          .img-wrapper {
            @media (max-width: 480px) {
              right: 110px;
            }
          }
        }

        .img-wrapper {
          display: flex;
          justify-content: center;
          overflow: visible;
          position: absolute;
          top: 0;
          right: 8%;

          @media (max-width: 1200px) {
            width: 100%;
            bottom: -240px;
            right: 50%;
            transform: translateX(50%);
          }

          @media (max-width: 768px) {
            bottom: -240px;
          }

          @media (max-width: 480px) {
            bottom: -120px;
          }

          img {
            width: auto;
            max-width: 550px;
            height: auto;
            transition: all 0.4s ease-in-out;

            @media (max-width: 1200px) {
              width: 100%;
            }
          }
        }
      }
    }

    /* 📱 Mobile Layout Adjustments */
    @media (max-width: 1200px) {
      .tp-spotlight-grid {
        display: flex !important;
        flex-direction: column;
        gap: 15px;
      }

      .spotlight-item {
        width: 100% !important;
      }

      .wide {
        width: 100% !important;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.bg-0 {
  background-color: #131514;
} /* Black */
.bg-1 {
  background-color: #7856ff;
} /* Purple */
.bg-2 {
  background-color: #2b2e3f;
} /* Dark gray */
.bg-3 {
  background-color: #1d43f9;
} /* Blue */
.bg-4 {
  background-color: #bb2f80;
} /* Pinkish purple */
.bg-5 {
  background-color: #00003b;
} /* Black (same as first) */
