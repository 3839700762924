@mixin hover-effect {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }

  @media (hover: none) {
    &:active {
      @content;
    }
  }
}

@mixin width-below-768 {
  @media screen and (max-width: 768px) {
    @content;
  }
}
