.tp-main-faq {
  width: 100%;
  background-color: #f4f4f4;

  .tp-main-faq-wrapper {
    width: calc(100% - 72px);
    max-width: 1440px;

    margin: auto;
    padding: 120px 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;

    @media screen and (max-width: 1600px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 480px) {
      width: calc(100% - 32px);
      padding: 80px 0;

      gap: 36px;
    }

    .faq-header {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .faq-title {
        color: #2b2e3f;

        font-family: "Futura BdCn BT";
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 56px; /* 85.714% */
        letter-spacing: -1.12px;
        text-transform: uppercase;

        @media screen and (max-width: 480px) {
          font-size: 46px;
          line-height: 50px;
        }
      }

      .faq-desc {
        color: #2b2e3f;

        font-family: Figtree;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 125% */
        letter-spacing: 0.2px;

        @media screen and (max-width: 480px) {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }
}

.tp-faq-grid {
  display: flex;
  gap: 24px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 16px;
  }
}
