@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.red {
  color: #eb004e;
}

.gray {
  color: #838383;
}

.tpcard-main-container {
  width: 100%;
  height: 100%;

  .tpcard-hero {
    width: 100%;
    height: 100%;

    padding: 140px 0 300px;

    position: relative;

    @media screen and (max-width: 1024px) {
      padding: 120px 0 280px;
    }

    @media screen and (max-width: 768px) {
      padding: 60px 0 260px;
    }

    @media screen and (max-width: 520px) {
      padding: 60px 0 300px;
    }

    @media screen and (max-width: 480px) {
      padding: 60px 0 180px;
    }

    @media screen and (max-width: 360px) {
      padding: 60px 0 300px;
    }

    background: radial-gradient(
      circle at 0% -50%,
      #100f10 50%,
      #6a2ad3 80%,
      #f8a7ff 100%
    );

    .tpcard-hero-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      position: relative;

      @media screen and (max-width: 1600px) {
        width: calc(100% - 72px);
        flex-direction: column-reverse;
        max-width: 1200px;
      }

      @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        gap: 0px;
      }

      @media screen and (max-width: 768px) {
        width: calc(100% - 32px);
        max-width: 1200px;
      }

      .tpcard-hero-left {
        width: 100%;
        min-width: 580px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 24px;

        color: #ffffff;

        @media screen and (max-width: 1600px) {
          justify-content: center;
          align-items: center;

          min-width: 100%;
        }

        .tpcard-hero-title {
          text-transform: uppercase;

          @media screen and (max-width: 1600px) {
            text-align: center;
          }
        }

        .tpcard-hero-desc {
          width: 100%;
          max-width: 600px;

          @media screen and (max-width: 1600px) {
            text-align: center;
          }
        }

        .tpcard-hero-button {
        }
      }

      .tpcard-hero-img {
        max-width: 720px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  // Two Info Panel under Hero
  .tpcard-info-panel-hero-wrapper {
    height: auto;

    position: absolute;
    bottom: -180px;
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 32px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      bottom: -600px;
      align-items: center;
    }

    .tpcard-info-panel {
      width: 100%;
      max-width: 620px;
      background-color: #ffffff;

      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      padding: 48px 40px;
      border-radius: 32px;

      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);

      position: relative;

      @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 560px;
        gap: 12px;
      }

      @media screen and (max-width: 480px) {
        padding: 32px 32px;
      }

      .plan {
        color: #2b2e3f;
      }

      .title {
        color: #2b2e3f;
      }

      .tag {
        padding: 14px 20px;
        border-radius: 16px;

        margin-top: 4px;

        color: #ffffff;
        background: radial-gradient(
          circle at 0% -50%,
          #100f10 50%,
          #6a2ad3 80%,
          #f8a7ff 100%
        );
      }

      .second-tag {
        background: #bb2f80 !important;
      }

      .desc {
        color: #727272;

        margin-top: 4px;
      }

      .twoethcoinimg {
        position: absolute;
        top: -90px;
        right: 30px;

        @media screen and (max-width: 480px) {
          top: -80px;
          right: 10px;
          max-width: 160px;
        }

        @media screen and (max-width: 360px) {
          top: -120px;
        }
      }
    }
  }

  //Gradient Section ////////////////////////////////////////////////////////////////////////////
  .gradient {
    width: 100%;
    height: auto;

    padding-bottom: 120px;

    background: linear-gradient(
      rgba(255, 255, 255, 1) 0%,
      rgba(230, 223, 255, 1) 100%
    );

    @media screen and (max-width: 600px) {
      padding-bottom: 80px;
    }

    //What's Included Section - START ///////////////////////////////////////////////////////////////////
    .tpcard-whatsincluded-wrapper {
      padding-top: 300px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1024px) {
        padding-top: 740px;
        gap: 60px;
      }

      @media screen and (max-width: 768px) {
        padding-top: 680px;
        gap: 40px;
      }

      .whatsincluded-section-title {
        color: #2b2e3f;
        margin-bottom: 120px;

        @media screen and (max-width: 1024px) {
          margin-bottom: 80px;
        }

        @media screen and (max-width: 768px) {
          margin-bottom: 40px;
          text-align: center;
        }

        @media screen and (max-width: 480px) {
          margin-bottom: 20px;
        }
      }

      .tpcard-whatsincluded-panel {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 60px;

        color: #2b2e3f;

        @media screen and (max-width: 1024px) {
          gap: 30px;
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;

          gap: 16px;
        }

        &:nth-of-type(2) {
          flex-direction: row-reverse;

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }

        .tpcard-whatsincluded-img {
          width: 100%;

          img {
            width: 100%;
            max-width: 660px;
            min-width: 320px;
            height: auto;

            @media screen and (max-width: 1024px) {
              min-width: 250px;
            }

            @media screen and (max-width: 768px) {
              max-width: 100%;
              min-width: 100%;
            }
          }
        }

        .tpcard-whatsincluded-info {
          width: 100%;
          min-width: 520px;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          @media screen and (max-width: 1024px) {
            min-width: 420px;
          }

          @media screen and (max-width: 768px) {
            min-width: 100%;
            gap: 8px;
          }

          .tpcard-whatsincluded-title {
            text-transform: uppercase;
          }

          .tpcard-whatsincluded-desc {
          }
        }
      }
    }
    //What's Included Section - END ///////////////////////////////////////////////////////////////////

    //Card Benefits Section - START ///////////////////////////////////////////////////////////////////
    .tpcard-cardbenefits-wrapper {
      padding-top: 240px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 768px) {
        padding-top: 120px;
      }

      .cardbenefits-section-title {
        color: #2b2e3f;
        margin-bottom: 60px;
        text-align: center;
      }

      .tpcard-cardbenefits-panel {
        display: flex;
        align-items: center;

        height: auto;

        border-radius: 32px;

        color: #ffffff;

        @media screen and (max-width: 768px) {
          flex-direction: column-reverse;
          border-radius: 24px;
        }

        &:nth-of-type(1) {
          background-color: #bb2f80;
        }

        &:nth-of-type(2) {
          flex-direction: row-reverse;
          background-color: #563ddb;
          margin-top: 24px;

          .tpcard-cardbenefits-img {
            margin-right: 40px;

            @media screen and (max-width: 768px) {
              margin-right: 0;
            }
          }

          .tpcard-cardbenefits-info {
            padding: 60px 60px 60px 130px;

            @media screen and (max-width: 1024px) {
              padding: 40px 16px 40px 40px;
            }

            @media screen and (max-width: 768px) {
              padding: 40px 40px 0;
            }

            @media screen and (max-width: 480px) {
              padding: 24px 24px 0;
            }
          }

          @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
          }
        }

        &:nth-of-type(3) {
          color: #2b2e3f;
          background-color: #ffffff;
          margin-top: 24px;
        }

        .tpcard-cardbenefits-img {
          width: 100%;
          max-width: 460px;
          display: flex;

          margin-left: 40px;

          @media screen and (max-width: 768px) {
            margin-left: 0;
          }

          img {
            width: 100%;

            height: auto;

            @media screen and (max-width: 1024px) {
              min-width: 300px;
              height: 100%;
            }

            @media screen and (max-width: 768px) {
              width: 100%;
              min-width: 100%;
              max-width: 100%;
              height: auto;

              padding: 0 12px;
            }
          }
        }

        .tpcard-cardbenefits-info {
          height: fit-content;
          flex-grow: 1;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          padding: 60px 130px 60px 60px;

          @media screen and (max-width: 1024px) {
            padding: 40px 40px 40px 16px;
          }

          @media screen and (max-width: 768px) {
            padding: 40px 40px 0;
          }

          @media screen and (max-width: 480px) {
            padding: 24px 24px 0;
          }

          .tpcard-cardbenefits-title {
            text-transform: uppercase;
          }

          .tpcard-cardbenefits-desc {
            @media screen and (max-width: 1024px) {
              min-width: 380px;
            }

            @media screen and (max-width: 768px) {
              min-width: 100%;
            }
          }
        }
      }
    }
    //Card Benefits Section - END ///////////////////////////////////////////////////////////////////
  }

  //REFER AND EARN Section - START ///////////////////////////////////////////////////////////////////
  .tpcard-referandearn-container {
    width: 100%;
    height: auto;

    padding: 120px 0;

    background: linear-gradient(135deg, #0a0213, #380874);

    @media screen and (max-width: 768px) {
      padding: 80px 0;
    }

    .tpcard-referandearn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 120px;

      @media screen and (max-width: 1440px) {
        gap: 60px;
      }

      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }

      .tpcard-referandearn-left {
        width: 100%;
        min-width: 420px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        color: #ffffff;

        @media screen and (max-width: 1200px) {
          text-align: center;
          align-items: center;

          min-width: 100%;
          max-width: 600px;
        }

        .tpcard-referandearn-button {
          padding: 22px 32px;

          border-radius: 20px;

          color: #2b2e3f;
          background-color: #ffffff;

          cursor: pointer;
        }
      }

      img {
        width: 100%;
        max-width: 614px;
        height: auto;

        @media screen and (max-width: 1200px) {
          max-width: 500px;
        }
      }
    }
  }
  //REFER AND EARN Section - END ///////////////////////////////////////////////////////////////////

  //YOU’RE always in Control Section - END ///////////////////////////////////////////////////////////////////
  .tpcard-alwayscontrol-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #2b2e3f;

    padding: 120px 0;

    .section-title {
      text-align: center;
      width: calc(100% - 72px);

      @media screen and (max-width: 768px) {
        width: calc(100% - 32px);
      }
    }

    .section-desc {
      width: calc(100% - 72px);
      max-width: 780px;

      text-align: center;

      margin: 24px 16px 0 16px;

      @media screen and (max-width: 768px) {
        width: calc(100% - 32px);
      }
    }

    .tpcard-alwayscontrol-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;

      margin-top: 56px;

      .tpcard-alwayscontrol-panel {
        max-width: 440px;
        height: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 26px;

        background-color: #f4f4f4;
        border-radius: 32px;

        padding: 40px;

        @media screen and (max-width: 1024px) {
          padding: 30px;
        }

        .panel-title {
          text-align: center;
        }

        img {
          width: 100%;
          max-width: 340px;
          max-width: 266px;
          height: auto;
        }

        &:nth-of-type(1),
        &:nth-of-type(3) {
          width: 100%;
          max-width: 386px;
          height: auto;
          min-height: 440px;

          gap: 12px;

          img {
            width: 100%;
            max-width: 266px;
            height: auto;
          }
        }

        .panel-desc {
          text-align: center;
        }
      }

      .slider-container {
        width: 100%;
        overflow: visible;

        .slick-track {
          display: flex;
        }

        .slick-slide {
          margin: 0 10px 24px;
          pointer-events: none;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .slick-dots li button:before {
          font-size: 13px !important; /* Adjust dot size */
          color: #000000 !important; /* Default dot color */
        }

        .tpcard-alwayscontrol-slide {
          width: 100%;
          max-width: 440px;
          height: auto;

          display: flex !important;
          flex-direction: column;
          align-items: center !important;
          justify-content: center !important;
          gap: 26px;

          background-color: #f4f4f4;
          border-radius: 32px;

          @media screen and (max-width: 1024px) {
            padding: 30px;
          }

          .panel-title {
            text-align: center;
          }

          img {
            width: 100%;
            max-width: 340px;
            max-width: 266px;
            height: auto;
          }

          &:nth-of-type(1),
          &:nth-of-type(3) {
            width: 100%;
            max-width: 386px;
            height: auto;
            min-height: 440px;

            gap: 12px;

            img {
              width: 100%;
              max-width: 266px;
              height: auto;
            }
          }

          .panel-desc {
            text-align: center;
          }
        }
      }
    }
  }
  //YOU’RE always in Control Section - END ///////////////////////////////////////////////////////////////////
}
