html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  line-height: 1;
  vertical-align: baseline;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: transparent;
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-tap-highlight-color: transparent;
  -ms-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: transparent;
  tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  scroll-behavior: smooth;
}

:root {
  font-family: "Roboto", sans-serif;
  // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  //   monospace;
}

.secondary-title {
  font-family: "Futura BdCn BT";
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -1.12px;
  text-transform: uppercase;

  &.lh-double {
    line-height: 60px;
  }

  &.centered {
    text-align: center;
  }

  &.mobile-centered {
    @media screen and (max-width: 480px) {
      text-align: center;
    }
  }

  &.white {
    color: #ffffff;
  }

  &.dark {
    color: #2b2e3f;
  }
}
