.tp-main-crypto-tag-title {
  color: #838383;
  font-family: Figtree;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 125% */
  letter-spacing: 0.2px;

  text-align: center;

  margin-bottom: 24px;

  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 21px;
    width: 80%;
  }
}

.tp-main-crypto-tag {
  width: 100%;

  padding: 32px 0;

  background-color: #563ddb;

  @media screen and (max-width: 480px) {
    padding: 24px 0;
  }

  .mobile-crypto-tag-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .gap {
    margin: 0 12px;

    .tp-crypto-tag-wrapper {
      width: 280px;

      // flex: 1 1 auto;
      display: flex;
      gap: 16px;
      align-items: center;

      padding: 16px 40px 16px 24px;

      white-space: nowrap;

      background-color: #ffffff;
      border-radius: 500px;

      @media screen and (max-width: 480px) {
        width: 210px;
        gap: 12px;
        padding: 12px 0 12px 16px;
      }

      img {
        @media screen and (max-width: 480px) {
          width: 50px;
          height: auto;
        }
      }

      .crypto-tag-details {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .crypto-tag-title {
          color: #2b2e3f;

          font-family: Figtree;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 121.429% */
          letter-spacing: 0.28px;
          text-transform: capitalize;

          @media screen and (max-width: 480px) {
            font-size: 22px;
            line-height: 22px;
          }
        }

        .crypto-tag-sign {
          color: #7d7d7d;
          font-family: Figtree;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 121.429% */
          letter-spacing: 0.28px;
          text-transform: uppercase;

          @media screen and (max-width: 480px) {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
