.tp-main-usecase {
  width: 100%;
  height: auto;

  .tp-main-usecase-wrapper {
    width: calc(100% - 72px);
    max-width: 1440px;
    height: auto;

    margin: auto;
    padding: 120px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;

    border-top: solid 1px #dbdbdb;

    @media screen and (max-width: 1600px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 480px) {
      width: calc(100% - 32px);
      padding: 80px 0;

      gap: 36px;
    }

    .usecase-header {
      max-width: 1000px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .usecase-header-title {
        color: #563ddb;

        font-family: Futura BdCn BT;
        font-weight: 400;
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -2%;
        text-align: center;
        text-transform: uppercase;

        @media screen and (max-width: 480px) {
          width: 85%;
          font-size: 46px;
          line-height: 50px;
        }
      }

      .usecase-header-title-second {
        color: #2b2e3f;

        font-family: Futura BdCn BT;
        font-weight: 400;
        font-size: 56px;
        line-height: 56px;
        letter-spacing: -2%;
        text-align: center;
        text-transform: uppercase;

        @media screen and (max-width: 480px) {
          width: 85%;
          font-size: 36px;
          line-height: 30px;
        }
      }

      .usecase-header-desc {
        color: #2b2e3f;

        font-family: Figtree;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 125% */
        letter-spacing: 0.2px;

        text-align: center;

        @media screen and (max-width: 480px) {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    .usecase-category {
      width: 100%;
      height: auto;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 36px;

      @media screen and (max-width: 1200px) {
        gap: 20px;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: 16px;
      }

      .usecase-content-wrapper {
        width: 100%;
        max-width: 360px;
        height: 440px;
        gap: 24px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 46px;

        border-radius: 32px;

        background-color: #f4f4f4;
        outline: solid 1px #f4f4f4;

        transition: all 0.2s ease-in-out;

        @media screen and (max-width: 480px) {
          outline: none;
        }

        &:hover {
          outline: solid 4px #dddddd;

          @media screen and (max-width: 480px) {
            outline: none;
          }
        }

        @media screen and (max-width: 480px) {
          min-height: auto;
          height: auto;

          flex-direction: row;

          padding: 16px 16px 16px 8px;
          gap: 8px;

          border-radius: 16px;
        }

        img {
          width: 100%;

          height: auto;

          z-index: 10;

          @media screen and (max-width: 480px) {
            max-width: 120px;
          }
        }

        p {
          min-height: 96px;

          display: flex;
          align-items: center;

          color: #2b2e3f;

          text-align: center;
          font-family: Figtree;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px; /* 123.077% */
          letter-spacing: 0.26px;

          @media screen and (max-width: 480px) {
            font-size: 17px;
            line-height: 21px;

            text-align: left;
          }
        }
      }
    }
  }
}
