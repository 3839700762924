// Figtree

.Ft-14s-14l {
  font-family: Figtree;
  font-size: 14px;
  line-height: 14px;
}

.Ft-16s-16l {
  font-family: Figtree;
  font-size: 16px;
  line-height: 16px;
}

.Ft-16s-24l {
  font-family: Figtree;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 480px) {
    font-size: 14px !important;
    line-height: 20px;
  }
}

.Ft-18s-18l {
  font-family: Figtree;
  font-size: 18px;
  line-height: 1;

  @media screen and (max-width: 768px) {
    font-size: 16px !important;
  }
}

.Ft-18s-18l-s {
  font-family: Figtree;
  font-size: 18px;
  line-height: 1;
}

.Ft-18s-24l {
  font-family: Figtree;
  font-size: 18px;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 16px !important;
    line-height: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px !important;
  }
}

.Ft-20s-20l {
  font-family: Figtree !important;
  font-size: 20px !important;
  line-height: 1 !important;

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px !important;
  }
}

.Ft-20s-20l-s {
  font-family: Figtree !important;
  font-size: 20px !important;
  line-height: 1 !important;
}

.Ft-20s-24l {
  font-family: Figtree !important;
  font-size: 20px !important;
  line-height: 26px !important;
}

.Ft-20s-26l {
  font-family: Figtree;
  font-size: 20px;
  line-height: 26px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.Ft-20s-28l {
  font-family: Figtree;
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
    line-height: 26px !important;
  }
}

.Ft-22s-28l {
  font-family: Figtree;
  font-size: 22px;
  line-height: 28px;
}

.Ft-24s-24l {
  font-family: Figtree;
  font-size: 24px !important;
  line-height: 1 !important;

  @media screen and (max-width: 480px) {
    font-size: 20px !important;
    line-height: 1 !important;
  }
}

.Ft-24s-30l {
  font-family: Figtree;
  font-size: 24px !important;
  line-height: 30px !important;

  @media screen and (max-width: 480px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.Ft-26s-32l {
  font-family: Figtree;
  font-size: 26px;
  line-height: 32px;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
    line-height: 30px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
}

.Ft-32s-38l {
  font-family: Figtree;
  font-size: 32px;
  line-height: 38px;

  @media screen and (max-width: 992px) {
    font-size: 28px !important;
    line-height: 34px !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 22px !important;
    line-height: 28px !important;
  }
}

.Ft-36s-38l {
  font-family: Figtree;
  font-size: 36px;
  line-height: 38px;

  @media screen and (max-width: 768px) {
    font-size: 26px !important;
    line-height: 28px !important;
  }
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

// Futura

.Fu-40s-40l {
  font-family: Futura BdCn BT;
  font-size: 40px;
  line-height: 40px;
}

.Fu-64s-64l {
  font-family: Futura BdCn BT;
  font-size: 64px;
  line-height: 1;

  @media screen and (max-width: 768px) {
    font-size: 56px;
  }
}

.Fu-70s-70l {
  font-family: Futura BdCn BT;
  font-size: 70px;
  line-height: 1;

  @media screen and (max-width: 1024px) {
    font-size: 56px;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
}

.Fu-90s-90l {
  font-family: Futura BdCn BT;
  font-size: 90px;
  line-height: 90px;

  @media screen and (max-width: 1024px) {
    font-size: 90px;
    line-height: 90px;
  }

  @media screen and (max-width: 768px) {
    font-size: 80px;
    line-height: 80px;
  }

  @media screen and (max-width: 425px) {
    font-size: 60px;
    line-height: 60px;
  }
}

.Fu-120s-120l {
  font-family: Futura BdCn BT;
  font-size: 120px;
  line-height: 120px;

  @media screen and (max-width: 1600px) {
    font-size: 100px;
    line-height: 100px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 90px;
    line-height: 90px;
  }

  @media screen and (max-width: 768px) {
    font-size: 80px;
    line-height: 80px;
  }

  @media screen and (max-width: 425px) {
    font-size: 60px;
    line-height: 60px;
  }
}
