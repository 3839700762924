@font-face {
  font-family: "Futura BdCn BT";
  src: url("../../fonts/FUTURA\ BOLD\ FONT.woff2") format("woff2"),
    url("../../fonts/FUTURA_BOLD_FONT.woff") format("woff"),
    url("../../fonts/FUTURA\ BOLD\ FONT.TTF") format("truetype");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

.tp-main-page {
  width: 100wh;
  height: 100%;

  position: relative;
  overflow: hidden;

  //   background-color: rgb(241, 241, 255);
}
