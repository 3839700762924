.tp-main-hero {
  width: 100%;

  overflow: hidden;

  .tp-main-hero-wrapper {
    width: calc(100% - 72px);
    max-width: 1440px;

    margin: 0 auto 120px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;

    position: relative;

    @media screen and (max-width: 1600px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 992px) {
      gap: 0;
    }

    @media screen and (max-width: 768px) {
      margin: 40px auto 120px;
    }

    @media screen and (max-width: 480px) {
      width: calc(100% - 32px);

      justify-content: center;

      margin: 20px auto 80px;
    }

    .left-content-wrapper {
      width: 100%;
      min-width: 450px;

      display: flex;
      flex-direction: column;
      gap: 42px;

      @media screen and (max-width: 768px) {
        min-width: 300px;
      }

      @media screen and (max-width: 480px) {
        align-items: center;
      }

      .hero-title-desc-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
      }

      .tp-hero-title {
        color: #2b2e3f;

        font-feature-settings: 'ss01' on;
        font-family: 'Futura BdCn BT';
        font-size: 120px;
        font-style: normal;
        font-weight: 400;
        line-height: 120px;
        letter-spacing: -1.92px;
        text-transform: uppercase;

        @media screen and (max-width: 1600px) {
          font-size: 100px;
          line-height: 100px;
        }

        @media screen and (max-width: 992px) {
          font-size: 90px;
          line-height: 90px;
        }

        @media screen and (max-width: 768px) {
          font-size: 50px;
          line-height: 60px;
          text-align: center;

          display: flex;
          flex-direction: column;
        }

        @media screen and (max-width: 480px) {
          font-size: 50px;
          line-height: 60px;
          text-align: center;

          display: flex;
          flex-direction: column;
        }
      }

      .gradient {
        background: linear-gradient(89deg, #2d41f4 0%, #be309a 81.17%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        span {
          white-space: nowrap;
        }

        @media screen and (max-width: 768px) {
          line-height: inherit;
          font-size: 70px;
          text-align: center;
        }

        @media screen and (max-width: 480px) {
          font-size: 70px;
          text-align: center;
        }
      }

      .tp-hero-desc {
        color: #2b2e3f;

        text-align: left;
        font-family: Figtree;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.2px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          line-height: 23px;
          text-align: center;
        }

        @media screen and (max-width: 480px) {
          text-align: center;
          font-size: 18px;
          line-height: 23px;

          width: 90%;
        }
      }

      .reversed-button {
        flex-direction: row-reverse;
      }

      .hero-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 36px;

        @media screen and (max-width: 768px) {
          align-items: center;
        }
      }

      .sc-my-logo {
        width: 100%;
        max-width: 320px;
        height: auto;

        @media screen and (max-width: 480px) {
          max-width: 220px;
        }
      }

      .tp-hero-info {
        max-width: 500px;
        color: #838383;
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 131.25% */
        letter-spacing: 0.16px;

        @media screen and (max-width: 768px) {
          font-size: 15px;

          width: 95%;

          font-size: 15px;
          text-align: center;

          width: 95%;
        }

        span {
          display: inline;
        }

        a {
          color: #7856ff;
          text-decoration-line: underline;
          margin-left: 4px;

          @media screen and (max-width: 480px) {
            white-space: nowrap;
          }
        }
      }
    }

    .tp-hero-mobile-content-wrapper {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      .tp-heromobile-vid {
        width: 100%;
      }

      .mobile-image-wrapper {
        position: absolute;

        .mobile-phone-img {
          width: 100%;
        }

        .mobile-phone-element {
          position: absolute;
        }

        .first {
          height: 40%;
          top: 10%;
          left: 5%;

          animation: mobilefloat 4s ease-in-out infinite;

          @keyframes mobilefloat {
            0% {
              transform: translateX(0) translateY(0);
            }

            50% {
              transform: translateX(0) translateY(10px);
            }

            100% {
              transform: translateX(0) translateY(0);
            }
          }
        }

        .second {
          width: 50%;
          bottom: 35%;
          right: 0;

          animation: mobilefloat 3s ease-in-out infinite;

          @keyframes mobilefloat {
            0% {
              transform: translateX(0) translateY(0);
            }

            50% {
              transform: translateX(0) translateY(8px);
            }

            100% {
              transform: translateX(0) translateY(0);
            }
          }
        }

        .third {
          width: 50%;
          bottom: 19%;
          right: 5%;

          animation: mobilefloat 2s ease-in-out infinite;

          @keyframes mobilefloat {
            0% {
              transform: translateX(0) translateY(0);
            }

            50% {
              transform: translateX(0) translateY(6px);
            }

            100% {
              transform: translateX(0) translateY(0);
            }
          }
        }

        .fourth {
          width: 42%;
          bottom: 8%;
          right: 0;

          animation: mobilefloat 4s ease-in-out infinite;

          @keyframes mobilefloat {
            0% {
              transform: translateX(0) translateY(0);
            }

            50% {
              transform: translateX(0) translateY(6px);
            }

            100% {
              transform: translateX(0) translateY(0);
            }
          }
        }
      }
    }

    //RIGHT/////////////////////////////////////////////////////////////////////////////////////

    .tp-hero-img-wrapper {
      width: 100%;

      position: relative;

      margin-right: -380px;

      @media screen and (max-width: 1600px) {
        margin-right: -480px;
      }

      @media screen and (max-width: 992px) {
        margin-right: -580px;
      }

      @media screen and (max-width: 480px) {
      }

      .tp-hero-vid {
        height: 900px;
      }

      .phone-section-wrapper {
        position: absolute;

        bottom: 0;
        left: 0;

        @media screen and (max-width: 1600px) {
          left: -100px;
        }

        @media screen and (max-width: 992px) {
          left: -120px;
          bottom: 50px;
        }

        .phone-img {
          height: 820px;

          @media screen and (max-width: 992px) {
            height: 720px;
          }
        }

        .phone-element {
          position: absolute;
        }

        .first {
          width: auto;
          height: 320px;
          left: 15%;

          animation: float 4s ease-in-out infinite;

          @keyframes float {
            0% {
              transform: translateX(0) translateY(0);
            }

            50% {
              transform: translateX(0) translateY(12px);
            }

            100% {
              transform: translateX(0) translateY(0);
            }
          }
        }
        .second {
          width: 350px;
          height: auto;

          right: 10%;
          bottom: 35%;

          animation: float 3s ease-in-out infinite;

          @keyframes float {
            0% {
              transform: translateX(0) translateY(0);
            }

            50% {
              transform: translateX(0) translateY(10px);
            }

            100% {
              transform: translateX(0) translateY(0);
            }
          }
        }
        .third {
          width: 350px;
          height: auto;

          right: 0;
          bottom: 20%;

          animation: float 2s ease-in-out infinite;

          @keyframes float {
            0% {
              transform: translateX(0) translateY(0);
            }

            50% {
              transform: translateX(0) translateY(16px);
            }

            100% {
              transform: translateX(0) translateY(0);
            }
          }
        }

        .fourth {
          width: 35%;
          bottom: 9%;
          right: -4%;

          animation: mobilefloat 3s ease-in-out infinite;

          @keyframes mobilefloat {
            0% {
              transform: translateX(0) translateY(0);
            }

            50% {
              transform: translateX(0) translateY(6px);
            }

            100% {
              transform: translateX(0) translateY(0);
            }
          }
        }
      }
    }
  }
}

.learn-more {
  a {
    white-space: nowrap;
  }
}
