.tp-main-outrocta {
  width: 100%;
  height: auto;

  overflow: hidden;

  background-color: #f4f4f4;

  .border-radius {
    width: 100%;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;

    background-color: #ffffff;

    @media screen and (max-width: 480px) {
      border-radius: 0px;
    }
  }

  .tp-main-outrocta-wrapper {
    width: calc(100% - 72px);
    max-width: 1440px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;

    margin: auto;
    padding: 80px 24px 0;

    @media screen and (max-width: 1600px) {
      max-width: 1200px;
      gap: 60px;
    }

    @media screen and (max-width: 992px) {
      width: calc(100% - 32px);

      flex-direction: column;
      gap: 0;
      padding: 40px 0;
    }

    .outro-img {
      width: 100%;
      max-width: 500px;
      height: auto;

      @media screen and (max-width: 1200px) {
        width: 40%;
      }

      @media screen and (max-width: 992px) {
        width: 50%;
      }

      @media screen and (max-width: 480px) {
        width: 80%;
      }
    }

    .outrocta-right-content {
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media screen and (max-width: 992px) {
        align-items: center;
        gap: 20px;

        border-top: 1px solid #dbdbdb;
        padding-top: 24px;
      }

      .outrocta-text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media screen and (max-width: 480px) {
          gap: 16px;
          align-items: center;
          text-align: center;
        }

        .subscribers-wrapper {
          width: max-content;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          padding: 8px 16px;

          border-radius: 500px;
          border: 0.5px solid #7d7d7d;

          p {
            color: #7d7d7d;
            text-align: center;
            font-family: Figtree;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 100% */
          }
        }

        .outrocta-title {
          color: #2b2e3f;

          font-feature-settings: "salt" on;
          font-family: "Futura BdCn BT";
          font-size: 56px;
          font-style: normal;
          font-weight: 400;
          line-height: 48px; /* 85.714% */
          letter-spacing: -1.12px;
          text-transform: uppercase;

          @media screen and (max-width: 480px) {
            font-size: 42px;
            line-height: 42px;
          }
        }

        .gradient {
          background: linear-gradient(89deg, #2d41f4 0%, #be309a 81.17%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .outrocta-desc {
          color: #2b2e3f;

          font-family: Figtree;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px; /* 125% */
          letter-spacing: 0.2px;

          @media screen and (max-width: 480px) {
            font-size: 16px;
            line-height: 21px;

            width: 80%;
          }
        }
      }

      .outrocta-button-wrapper {
        width: 100%;
        display: flex;
        gap: 8px;

        @media screen and (max-width: 480px) {
          flex-direction: column;

          align-items: center;
        }

        .filled-width {
          @media screen and (max-width: 480px) {
            width: 80%;
          }
        }
      }
    }
  }
}
