.accordion {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .accordion-item {
    background-color: #ffffff;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    overflow: hidden;

    .accordion-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      gap: 16px;

      cursor: pointer;

      padding: 20px 28px 20px;

      @media screen and (max-width: 480px) {
        padding: 16px 20px 16px;
      }

      p {
        color: #2b2e3f;

        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;

        @media screen and (max-width: 480px) {
          font-size: 16px;
          line-height: 21px;
        }
      }

      img {
        @media screen and (max-width: 480px) {
          width: 20px;
          height: auto;
        }
      }

      &:hover {
        // background-color: #e7e7e7;
      }
    }

    .accordion-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;

      .accordion-inner {
        padding: 0 28px 20px; // Padding applied initially
        transition: padding 0.3s ease; // Transition for padding

        @media screen and (max-width: 480px) {
          padding: 0 20px 16px;
        }

        p {
          color: #2b2e3f;

          font-family: Figtree;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;

          @media screen and (max-width: 480px) {
            font-size: 15px;
          }
        }
      }

      &.open {
        max-height: 100vh; // Or a value large enough for your content

        .accordion-inner {
          //   padding: 0 28px 20px; // Padding applied when open
        }
      }
    }
  }
}
