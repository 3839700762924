.fallback-container {
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background-color: #f8f8f8;
  text-align: center;

  .fallback-title {
  }

  .fallback-desc {
  }
}

.intro-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  z-index: 1000;

  transition: transform 1s ease;

  overflow: hidden;
  pointer-events: none;

  background-color: rgba(255, 255, 255, 0.74);
  backdrop-filter: blur(100px); /* Adjust the blur amount here */
  -webkit-backdrop-filter: blur(100px); /* For Safari support */

  @media screen and (max-width: 1440px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    gap: 16px;
  }

  @media screen and (max-width: 480px) {
    gap: 12px;
  }

  .logo-wrapper {
    width: auto;
    height: auto;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding: 0;

    // background-color: #ffffff;

    transition: transform 1s ease-in-out;

    @media screen and (max-width: 1440px) {
      justify-content: center;
    }

    .torum-pay-logo {
      min-width: 450px;
      height: auto;

      @media screen and (max-width: 768px) {
        min-width: 350px;
      }

      @media screen and (max-width: 480px) {
        min-width: 250px;
      }
    }
  }

  .intro-text {
    width: auto;
    height: 46px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 60px;

    font-weight: 400;
    font-size: 18px;
    text-align: center;

    color: #ffffff;

    transition: transform 1s ease-in-out;

    border-radius: 100px;

    background: linear-gradient(
      220deg,
      rgb(53, 66, 189),
      #a886df,
      #162020,
      #4d14b8,
      #6438cc,
      #081e38,
      #081e38,
      #081e38,
      #081e38
    );
    background-size: 800% 800%;
    animation: gradientToWhite 4s ease forwards;

    @keyframes gradientToWhite {
      0% {
        background-position: 0% 50%;
      }
      25% {
        background-position: 100% 50%;
      }
      50% {
        background-position: 100% 50%;
        color: #ffffff;
      }
      75% {
        background-position: 0% 50%;
        color: #ffffff;
      }
      100% {
        background-position: 0% 50%;
        color: #ffffff;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0 40px;

      font-size: 18px;

      height: 40px;
    }

    @media screen and (max-width: 480px) {
      padding: 12px 30px;

      font-size: 15px;

      min-height: 44px;
      margin: 0 24px;

      p {
        line-height: 20px;
      }
    }
  }
}

.slide-left {
  transform: translateX(-150vh);
  pointer-events: none; // Make it non-interactive after fade-out
}

.slide-left-more {
  transform: translateX(-150vh);
  pointer-events: none; // Make it non-interactive after fade-out
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}
