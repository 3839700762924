@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.tpcard-cpdifference-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 240px;

  @media screen and (max-width: 600px) {
    margin-top: 160px;
  }

  .section-title {
    color: #2b2e3f;

    text-align: center;
  }

  .section-desc {
    max-width: 780px;

    margin: 12px auto 0;
    text-align: center;

    color: #2b2e3f;
    text-align: center;
  }

  .tpcard-cpdifference-chart-container {
    width: 100%;
    max-width: 1250px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #2b2e3f;
    background-color: #ffffff;

    border-radius: 32px;
    padding: 32px;
    margin-top: 56px;

    @media screen and (max-width: 1024px) {
      padding: 16px;
    }

    .tpcard-cpdifference-chart-panel {
      width: 100%;

      padding: 0 16px;

      display: flex;
      flex-direction: column;
      align-items: center;

      &:nth-of-type(1) {
        max-width: 300px;
      }

      &:nth-of-type(2) {
        background-color: #e6dfff;
        border-radius: 26px;
      }

      .header-row {
        display: flex;
        flex-direction: column;
        gap: 16px !important;

        min-height: 160px !important;
      }

      .underlined {
        display: flex;
        flex-direction: column;
        gap: 12px !important;

        border-bottom: #838383 solid 0.5px;
      }

      .slot-row {
        min-height: 126px !important;
        padding: 16px 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px !important;
      }

      .first-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: center !important;
        text-align: left !important;
      }

      .slot {
        width: 100%;
        max-width: 220px;
        height: auto;
        min-height: 90px;
        max-height: 126px;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        text-align: center;
      }
    }
  }
}

.tp-card-mobilechart-container {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 32px;
  margin-top: 56px;
  border-radius: 32px;

  background-color: #ffffff;

  @media screen and (max-width: 600px) {
    padding: 0;
    background: none;
    border-radius: 24px;
  }

  .tp-card-mobilechart-wrapper {
    width: 100%;

    padding: 16px;
    border-radius: 26px;

    color: #2b2e3f;
    background-color: #f4f4f4;

    @media screen and (max-width: 600px) {
      background-color: #ffffff;
    }

    &:nth-of-type(1) {
      background-color: #e6dfff;

      @media screen and (max-width: 600px) {
        background-color: #ffffff;
      }
    }

    .panel-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 24px;

      cursor: pointer;

      @media screen and (max-width: 600px) {
        padding: 0;
      }

      .panel-header-left {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .detail-panel-container {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      overflow: hidden;

      .detail-panel {
        width: 100%;
        padding: 16px 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        gap: 24px;

        @media screen and (max-width: 600px) {
          padding: 12px 0;
        }

        &:nth-of-type(1) {
          border-top: #c4c4c4 1px solid;
          margin-top: 16px;
        }

        .label {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 4px;

          span {
            font-size: inherit;
            color: inherit;
          }
        }

        .data {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          gap: 8px;

          .slot-row {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            gap: 8px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
