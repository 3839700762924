@import "../../styles/mixins.scss";

.tp-main-header {
  display: flex;
  justify-content: center;

  background-color: rgb(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 6px -10px rgb(0, 0, 0, 0.05);

  position: sticky;
  top: 0;
  z-index: 999;

  .tp-main-header-wrapper {
    width: calc(100% - 72px);
    max-width: 1440px;
    height: 66px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1600px) {
      max-width: 1200px;
    }

    .tp-main-header-page-button-wrapper {
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .tp-page-button {
        min-height: 40px;
        width: auto;

        display: inline-flex;
        align-items: center;

        padding: 0 12px;

        cursor: pointer;

        font-family: figtree;
        font-weight: 500;
        font-size: 17px;

        color: #c2c2c2;

        @include hover-effect {
          color: #000000;
        }
      }

      .active {
        color: #000000;
      }
    }

    .getstarted-lang-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      width: 196px;
    }

    // Mobile Version ------------------------------------------------------------
    &.mobile-header {
      width: calc(100% - 32px);

      img {
        width: 160px;
        margin-right: auto;
      }
    }
  }

  .tp-header-nav-icon {
    position: relative;

    display: flex;

    border-radius: 16px;

    padding: 10px 0;

    z-index: 999;

    cursor: pointer;

    transition: all 0.3 ease-in-out;

    .nav-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .nav-panel-container {
      position: absolute;
      top: 50px;
      right: 0;

      z-index: -1;

      height: auto;

      opacity: 0;

      display: flex;
      flex-direction: column;

      border-radius: 16px;

      overflow: hidden;

      background-color: #ffffff;

      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);

      transition: max-height 0.3 ease-in-out;

      &.show {
        opacity: 1;
      }

      &.hide {
        opacity: 0;
      }

      .nav-panel {
        width: 100%;
        padding: 20px 36px;
        text-wrap: nowrap;

        cursor: pointer;

        color: #c2c2c2;

        text-align: right;

        &.active {
        }

        &:nth-of-type(1) {
          padding: 24px 30px 20px;
        }

        &:nth-of-type(2) {
          padding: 20px 30px 24px;
        }

        @media (hover: hover) {
          &:hover {
            background-color: #d0cceb;
          }
        }

        @media (hover: none) {
          &:active {
            background-color: #d0cceb;
          }
        }
      }
    }
  }
}
