.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-family: "Figtree", sans-serif;
  font-style: normal;

  cursor: pointer;

  transition: all 0.2s ease-in-out;

  //Reverse Column
  &.reversed-cr {
    flex-direction: column-reverse;
  }

  //Reverse Row
  &.reversed-rr {
    flex-direction: row-reverse;
  }

  // Filled Button CSS
  &.filled {
    color: #ffffff;
    background-color: #563ddb;

    gap: 8px;

    padding: 0 16px;
    border: none;
    border-radius: 16px;

    font-size: 16px;
    font-weight: 600;

    width: max-content;
    height: 42px;

    &.active {
      color: #ffffff;
      background-color: #2b2e3f;
    }

    &:hover {
      color: #ffffff;
      background-color: #2b2e3f;

      .button-icon {
        fill: currentColor; /* Ensure SVG uses currentColor */
      }
    }

    &.disabled {
      cursor: not-allowed;

      &:hover {
        color: #ffffff;
        background-color: #563ddb;

        .button-icon {
          fill: currentColor; /* Ensure SVG uses currentColor */
        }
      }
    }
  }

  // Filled Expanded Button CSS
  &.filled-expand {
    color: #ffffff;
    background-color: #563ddb;

    gap: 10px;

    padding: 16px 32px;
    border-radius: 20px;

    font-size: 18px;
    font-weight: 600;

    width: max-content;
    height: auto;

    &.active {
      color: #ffffff;
      background-color: #2b2e3f;
    }

    &:hover {
      color: #ffffff;
      background-color: #2b2e3f;

      .button-icon {
        fill: currentColor; /* Ensure SVG uses currentColor */
      }
    }

    &.disabled {
      cursor: not-allowed;

      &:hover {
        color: #ffffff;
        background-color: #563ddb;
      }
    }
  }

  // Outline Light Button CSS
  &.outlined-light {
    color: #ffffff;

    gap: 8px;

    padding: 16px 32px;
    border-radius: 20px;
    border: 1px solid #fff;

    font-size: 18px;
    font-weight: 400;

    width: max-content;

    &.active {
      color: #ffffff;
      background-color: #2b2e3f;
    }

    &:hover {
      //   color: #ffffff;
      //   background-color: #2b2e3f;

      .button-icon {
        fill: currentColor; /* Ensure SVG uses currentColor */
      }
    }

    &.disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }

  // Outline Dark Button CSS
  &.outlined-dark {
    color: #2b2e3f;
    background-color: #ffffff;
    border: 1px solid #2b2e3f;

    gap: 8px;

    padding: 0 16px;
    border-radius: 16px;

    font-size: 16px;
    font-weight: 600;

    width: max-content;
    height: 42px;

    &.active {
      color: #ffffff;
      background-color: #2b2e3f;
    }

    &:hover {
      color: #ffffff;
      background-color: #2b2e3f;

      .button-icon {
        fill: currentColor; /* Ensure SVG uses currentColor */
      }
    }

    &.disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }

  // Empty Button CSS but with clickable areas
  &.empty {
    color: #2b2e3f;

    padding: 0 16px;
    border: none;

    gap: 8px;

    font-size: 16px;
    font-weight: 600;

    width: max-content;
    height: 42px;

    margin: auto;

    &.link {
      color: #563ddb;
    }

    &:hover {
      .button-icon {
        fill: currentColor; /* Ensure SVG uses currentColor */
      }
    }

    &.disabled {
      //   opacity: 0.65;
      //   cursor: not-allowed;
    }
  }

  // Card info section button
  &.card-section {
    color: #838383;

    gap: 8px;

    padding: 16px 24px;
    border: none;
    border-radius: 20px;

    font-size: 18px;
    font-weight: 500;

    width: max-content;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }

    &.active {
      color: #ffffff;
      background-color: #5b73fd;

      background: #1a1a1a;

      font-weight: 600;
    }

    &:hover {
      color: #ffffff;
      //   background-color: #2b2e3f;

      .button-icon {
        fill: currentColor; /* Ensure SVG uses currentColor */
      }
    }

    &.disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }

  // For Outro CTA Section
  &.outro-cta {
    color: #ffffff;
    background-color: #2b2e3f;

    gap: 8px;

    padding: 16px 24px;
    border: none;
    border-radius: 20px;

    font-size: 18px;
    font-weight: 500;

    width: max-content;

    &.active {
      color: #ffffff;
      background-color: #5b73fd;

      background: #1a1a1a;

      font-weight: 600;
    }

    &:hover {
      color: #ffffff;
      background-color: #424663;

      .button-icon {
        fill: currentColor; /* Ensure SVG uses currentColor */
      }
    }

    &.disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }

  //Mobile Version -------------------------------------------------------------------------------------------------------------------------------
  &.mobile-filled {
    color: #ffffff;
    background-color: #563ddb;

    gap: 8px;

    padding: 0 16px;
    border: none;
    border-radius: 16px;

    font-size: 16px;
    font-weight: 600;

    width: max-content;
    height: 42px;

    &:active {
      color: #ffffff;
      background-color: #2b2e3f;

      &.button-icon {
        fill: currentColor; /* Ensure SVG uses currentColor */
      }
    }

    &.disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }
}

.hero-button {
  width: max-content;

  margin-top: 16px;
  padding: 16px 32px;
  border-radius: 16px;

  color: #000000;
  background-color: #ffffff;

  &.disabled {
    opacity: 1;
    cursor: not-allowed;
  }
}
